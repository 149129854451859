import { api } from "@converse/headless/converse-core";
import { component } from 'haunted';
import { html } from 'lit-html';

export const ConverseBrandHeading = (o) => {
    const is_fullscreen = api.settings.get('view_mode') === 'fullscreen';
    return html`
        <a class="brand-heading" href="https://conversejs.org" target="_blank" rel="noopener">
            <span class="brand-name-wrapper ${is_fullscreen ? 'brand-name-wrapper--fullscreen' : '' }">
                <!-- No heading here (for the moment) -->
                <span class="brand-name">
                    <span class="brand-name__text">Web<span class="subdued">chat</span></span>
                    ${ is_fullscreen ? html`<p class="byline">Freie Universit&auml;t Berlin</p>` : '' }
                </span>
            </span>
        </a>
        ${ is_fullscreen ? html`
            <p class="brand-subtitle">Anmeldung mit FU-Benutzername und Passwort</p>` : '' }
        `;
}

api.elements.define('converse-brand-heading', component(ConverseBrandHeading, {'useShadowDOM': false}));
