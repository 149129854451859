import { html } from "lit-html";


export default () => html`
    <div class="inner-content converse-brand row">
        <div class="converse-brand__padding"></div>
        <div class="converse-brand__heading">
            <!-- No image here (for the moment) -->
            <span class="converse-brand__text">
                <span>Web<span class="subdued">chat</span></span>
                <p class="byline">Freie Universit&auml;t Berlin</p>
            </span>
        </div>
    </div>`;
